.truncate {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bm-cross-button {
  height: 24px;
  width: 64px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #830000;
}

.ExtendedBold {
  font-family: "ExtendedBold" !important;
}
.ExtendedItalic {
  font-family: "ExtendedItalic" !important;
}
.WideNormal {
  font-family: "WideNormal" !important;
}
.WideBold {
  font-family: "WideBold" !important;
}
.Normal {
  font-family: "Normal" !important;
}
.ExtendedNormal {
  font-family: "ExtendedNormal" !important;
  font-size: 16px !important;
  margin-top: 25px !important;
  margin-left: 20px !important;
}
.main {
  margin: auto;
  position: relative;
}
.content {
  max-width: 1920px !important;
  margin: auto;
}
.reddd {
  border: 3px solid;
  border-color: red;
}
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.ui.text.menu {
  font-size: 1.875rem;
  height: 6rem;
  padding: 0rem 10.4rem 0rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.ui.container::deep() {
  gap: 1rem;
}

.item {
  font-family: "AudiWide" !important;
  font-weight: 800 !important;
  font-size: 25px;
  font-size: 1.4rem;
  color: black !important;
  /* margin-right: 3.5rem !important; */
}
.menu-image-block {
  /* padding: 5rem 5rem; */
  padding-left: 10.4rem;
  padding-top: 98px !important;
  padding-bottom: 96px !important;
  width: auto !important;
  background-size: cover;
  margin-top: 0 !important;
  max-width: 150% !important;
  height: 1009px !important;
}
#about {
  padding: 5rem 10.4rem 5rem;
}
.aboutButton {
  color: #f50537 !important;
  width: 18rem;
  margin-top: -1rem !important;
  border-radius: 0px !important;
}
.aboutButtonMobile {
  color: #f50537 !important;
  width: 12rem;
  margin-top: -1rem !important;
  background-color: white !important;
  margin-left: 2rem !important;
  border-radius: 0px !important;
}
.instagram {
  width: 2.5rem;
  height: 2.5rem;
}
.nftInstaNames {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  font-size: "32px" !important;
}
.footerImages {
  width: fit-content;
  height: 45px;
  width: 45px;
  float: right;
  margin-right: 2rem;
}
.footerImagesMobile {
  /* width: fit-content; */
  height: 29px !important;
  width: 29px !important;
  float: left;
  margin-right: 2rem;
}

.layered {
  display: grid;

  /* Set horizontal alignment of items in, case they have a diffrent width. */
  /* justify-items: start | end | center | stretch (default);*/
  justify-items: center;

  /* Set vertical alignment of items, in case they have a diffrent height. */
  /* align-items: start | end | center | stretch (default); */
  align-items: center;
}

.layered > * {
  grid-column-start: 1;
  grid-row-start: 1;
}

/* for demonstration purposses only */
.layered > * {
  outline: 1px solid red;
  background-color: rgba(255, 255, 255, 0.4);
}

.textFont {
  font-family: "AudiExtended" !important;
}
.center {
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
}
hr {
  border: 0.01rem solid black !important;
  background-color: black !important;
}

.tiledBackground {
  background-image: url(/public/assets/mobileFirstPage.png);
  background-size: 100%;
  /* width: 100%; */
  height: fit-content !important;
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;
  background-position: top !important;
  margin-top: -43px !important;
  padding-top: -10px !important;
  /* /* width: 438px !important; */
  /* height: 417px !important; */
  width: 100% !important;
  height: 100% !important;
  /* height: 418 !important; */
}
.pageNum {
  font-size: rem !important;
  float: left;
  margin-left: 1rem;
  color: black;
}

.pageNum:link {
  color: black;
}

/* visited link */
.pageNum:visited {
  color: black;
}

/* mouse over link */
.pageNum:hover {
  color: black;
}

/* selected link */
.pageNum:active {
  color: black;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 1rem;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.arrow {
  border: solid grey;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  float: left;
  margin-top: 0.3rem;
}

.sharpCorners {
  border-radius: 0px !important;
}
.spinner {
  justify-content: center;
  align-items: center;
}

.popUpButton {
  border: solid 1px;
  border-color: grey;
  padding: 1.5rem;
  text-align: center;
  margin-top: 0.3rem;
  font-size: 1.3rem;
  font-family: "WideNormal" !important;
}
.popUpButtonDesktop {
  border: solid 1.2px;
  border-color: grey;
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: center;
  margin-top: 1rem;
  font-size: 16px;
  float: left;
  margin-left: 0.3rem;
  width: 20rem;
  font-family: "WideNormal" !important;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-weight: normal !important;
  font-family: "ExtendedNormal" !important;
}
