body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@font-face {
  font-family: "ExtendedBold";
  src: local("AudiType-ExtendedBold_4.03"),
    url(./fonts/AudiType-ExtendedBold_4.03.ttf) format("truetype");
}

@font-face {
  font-family: "ExtendedItalic";
  src: local("AudiType-ExtendedItalic_4.03"),
    url(./fonts/AudiType-ExtendedItalic_4.03.ttf) format("truetype");
}
@font-face {
  font-family: "ExtendedNormal";
  src: local("AudiType-ExtendedNormal_4.03"),
    url(./fonts/AudiType-ExtendedNormal_4.03.ttf) format("truetype");
}

@font-face {
  font-family: "WideNormal";
  src: local("AudiType-WideNormal_4.03"),
    url(./fonts/AudiType-WideNormal_4.03.ttf) format("truetype");
}

@font-face {
  font-family: "WideBold";
  src: local("AudiType-WideBold_4.03"), url(./fonts/AudiType-WideBold_4.03.ttf) format("truetype");
}

@font-face {
  font-family: "Normal";
  src: local("AudiType-Normal_4.03"), url(./fonts/AudiType-Normal_4.03.ttf) format("truetype");
}

* {
  font-family: "ExtendedBold" !important;
  /* font-family: "AudiExtended" !important; */
}
